import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import {
  Spinner,
  Card,
  CardHeader,
  Row,
  Col,
  CardBody,
  Input,
  InputGroup,
  ButtonGroup,
  Badge,
} from "reactstrap";
import {
  AccountApiClient,
  AccountQueryParams,
} from "../../infrastructure/MyDayClient/Accounts";
import { TableHeader } from "../../infrastructure/TableHeader";
import Moment from "react-moment";

export class AccountsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: false,
      total: 0,
      skip: 0,
      take: 50,
      search: "",
      sort: "companyName",
      direction: "asc",
      showSuspended: false,
      status: "",
    };
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleShowSuspended = this.handleShowSuspended.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
  }

  handleSearchChange(evt) {
    this.setState({
      search: evt.target.value,
    });
  }

  handleStatusChange(evt) {
    this.setState(
      {
        status: evt.target.value,
      },
      () => this.update()
    );
  }

  handleShowSuspended = (evt) => {
    this.setState(
      {
        showSuspended: evt.target.checked,
      },
      () => this.update()
    );
  };

  async clear() {
    this.setState(
      {
        search: "",
        skip: 0,
      },
      () => this.update()
    );
  }

  componentDidMount() {
    this.update();
  }

  async update() {
    await this.populateData(this.state.skip, this.state.take);
  }

  renderTable(data) {
    var columns = [
      { name: "Company", sort: "companyName" },
      { name: "Contact", sort: "contactName" },
      { name: "Email", sort: "email" },
      { name: "Country", sort: "country" },
      { name: "Identity Provider", sort: "identityProvider" },
      { name: "Status", sort: "status" },
      { name: "Created", sort: "created" },
      { name: "", sort: "", static: true },
    ];

    return (
      <div className="table-responsive">
        <table className="table" aria-labelledby="tabelLabel">
          <TableHeader
            sort={this.state.sort}
            direction={this.state.direction}
            columns={columns}
            onUpdate={(sort, direction) =>
              this.setState(
                {
                  sort: sort,
                  direction: direction,
                },
                () => this.update()
              )
            }
          />
          <tbody>
            {data.map((item) => (
              <tr key={item.id}>
                <td>
                  {item.companyName && <span>{item.companyName}</span>}
                  {!item.companyName && <i>Name not specified</i>}
                </td>
                <td>
                  {item.contactName && <span>{item.contactName}</span>}
                  {!item.contactName && <i>Name not specified</i>}
                </td>
                <td>
                  {item.email && item.email}
                  {!item.email && <i>Not specified</i>}
                </td>
                <td>
                  {item.country && item.country}
                  {!item.country && <i>Not specified</i>}
                </td>
                <td>
                  {item.identityProvider && item.identityProvider}
                  {!item.identityProvider && <i>Not specified</i>}
                </td>
                <td>{item.status}</td>
                <td>
                  {item.created && (
                    <Moment utc local format="ddd DD MMM, h:mm a">
                      {item.created}
                    </Moment>
                  )}
                </td>
                <td>
                  <Link
                    key={item.id + "-view"}
                    style={{ float: "right" }}
                    className="btn mr-2 btn-outline-dark mt-2"
                    to={"/accounts/details/" + item.id}
                  >
                    Details
                  </Link>
                  <Link
                    key={item.id + "-edit"}
                    style={{ float: "right" }}
                    className="btn mr-2 btn-outline-dark mt-2"
                    to={"/accounts/edit/" + item.id}
                  >
                    Edit
                  </Link>
                </td>
              </tr>
            ))}
            {this.state.data.length === 0 && (
              <tr>
                <td colSpan="8">
                  <h4 className="text-muted text-center mt-3">
                    <i>No accounts to display</i>
                  </h4>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  }

  render() {
    const { hideFilter, description } = this.props;

    return (
      <div>
        <Card>
          <CardHeader>
            <Row>
              <Col>
                <h5>
                  {" "}
                  Accounts ({this.state.total})
                  {this.state.loading && (
                    <Spinner
                      style={{
                        height: "18px",
                        width: "18px",
                        marginLeft: "10px",
                      }}
                      animation="border"
                    />
                  )}
                </h5>
                <span className="d-block m-t-5 text-muted">
                  {description}{" "}
                  {!description && "List of accounts in the system"}
                </span>
              </Col>
              <Col>
                <div style={{ paddingRight: "10px" }}>
                  <button
                    style={{ float: "right" }}
                    onClick={() => {
                      this.next();
                    }}
                    disabled={
                      this.state.loading ||
                      this.state.skip + this.state.take >= this.state.total
                    }
                    className="btn mr-2 btn-outline-dark mt-2 mb-2"
                  >
                    Next
                  </button>
                  <button
                    style={{ float: "right" }}
                    onClick={() => {
                      this.previous();
                    }}
                    disabled={this.state.loading || this.state.skip <= 0}
                    className="btn mr-2 btn-outline-dark mt-2 mb-2"
                  >
                    Prev
                  </button>
                </div>
              </Col>
            </Row>
          </CardHeader>
        </Card>
        <Card>
          {!hideFilter && (
            <>
              <CardHeader>
                <Row>
                  <Col>
                    <h5>Filter</h5>
                  </Col>

                  {this.state.showSuspended && (
                    <Col>
                      <div className="form-group">
                        <InputGroup>
                          <Input
                            type="select"
                            name="select"
                            value={this.state.status}
                            onChange={this.handleStatusChange}
                          >
                            <option value="">All statuses</option>
                            <option value="Active">Active</option>
                            <option value="Suspended">Suspended</option>
                          </Input>
                        </InputGroup>
                      </div>
                    </Col>
                  )}
                  <Col>
                    <div style={{ paddingRight: "10px" }}>
                      <InputGroup>
                        <Input
                          type="text"
                          placeholder="Search"
                          value={this.state.search}
                          onChange={this.handleSearchChange}
                          onKeyPress={(e) => e.key === "Enter" && this.update()}
                        />
                        <ButtonGroup className="input-group-append">
                          <button
                            onClick={() => {
                              this.update();
                            }}
                            disabled={this.state.loading}
                            className="btn btn-outline-dark"
                          >
                            <span className="pcoded-micon">
                              <i className="feather icon-search"></i>
                            </span>
                          </button>
                        </ButtonGroup>
                        <ButtonGroup>
                          {this.state.search && (
                            <button
                              onClick={() => {
                                this.clear();
                              }}
                              className="btn btn-outline-dark ml-2"
                            >
                              <span className="pcoded-micon">Clear</span>
                            </button>
                          )}
                        </ButtonGroup>
                      </InputGroup>
                    </div>
                  </Col>
                </Row>
              </CardHeader>

              <CardHeader>
                <div className="form-group">
                  <ul
                    className="list-unstyled list-inline"
                    style={{ float: "right", margin: "0" }}
                  >
                    <li className="list-inline-item">
                      <label style={{ margin: "0" }}>
                        <input
                          name="showSuspended"
                          type="checkbox"
                          checked={this.state.showSuspended}
                          onChange={this.handleShowSuspended}
                        />{" "}
                        Show suspended accounts
                      </label>
                    </li>
                  </ul>
                </div>
              </CardHeader>
            </>
          )}

          <CardBody className="p-0">
            {this.state.data &&
              this.renderTable(
                this.state.data,
                this.state.nextPage,
                this.state.previousPage
              )}
          </CardBody>
        </Card>
      </div>
    );
  }

  async previous() {
    await this.populateData(this.state.skip - this.state.take, this.state.take);
  }

  async next() {
    await this.populateData(this.state.skip + this.state.take, this.state.take);
  }

  async populateData(skip, take) {
    if (!this.state.loading) {
      this.setState({ loading: true, skip: skip, take: take });

      var query = new AccountQueryParams()
        .Paginate(skip, take)
        .Search(this.state.search);

      if (!this.state.showSuspended)
        query = query.WithParam("status", "Active");

      if (this.state.showSuspended && this.state.status)
        query = query.WithParam("status", this.state.status);

      var response = await AccountApiClient.List(
        query.Sort(this.state.sort, this.state.direction)
      );

      this.setState({
        data: response.data.data,
        loading: false,
        total: response.data.total,
      });
    }
  }
}
