import { MyDayAdminAPI } from "./MyDayAdminAPI";

export async function GetAPIDomain() {
  var domain = localStorage.getItem("api-domain");

  if (!domain) {
    const response = await fetch("app-settings/domains");
    const data = await response.json();

    domain = data.domainApi;
    localStorage.setItem("api-domain", domain);
  }

  return domain;
}

export async function GetAPIHeadersTokenOnly() {
  var expiry = new Date(Date.parse(localStorage.getItem("expires")));

  if (expiry < new Date()) {
    console.log("TODO: Expired - refresh token");
    //await MyDayAdminAPI.RefreshToken();
  }

  var accessToken = localStorage.getItem("access-token");
  return new Headers({ Authorization: "Bearer " + accessToken });
}

export async function GetAPIHeaders(contentType) {
  var expiry = new Date(Date.parse(localStorage.getItem("expires")));

  if (expiry < new Date()) {
    console.log("TODO: Expired - refresh token");
    //await WellStreamAPI.RefreshToken();
  }

  var accessToken = localStorage.getItem("access-token");
  var headers = new Headers({
    "content-type": contentType ? contentType : "application/json",
    Authorization: "Bearer " + accessToken,
  });

  return headers;
}

export async function GetAPIHeadersPatch() {
  var expiry = new Date(Date.parse(localStorage.getItem("expires")));

  if (expiry < new Date()) {
    console.log("TODO: Expired - refresh token");
    //await WellStreamAPI.RefreshToken();
  }

  var accessToken = localStorage.getItem("access-token");

  var headers = new Headers({
    "content-type": "application/json-patch+json",
    Authorization: "Bearer " + accessToken,
  });

  return headers;
}
