import React, { Component } from 'react';
import { InvitationsTable } from './InvitationsTable';

export class Invitations extends Component {

    render() {
        return (
            <InvitationsTable />
        );
    }

}