import { ListQueryParams, ApiResponse } from "../MyDayAdminAPI";
import { GetAPIHeadersPatch, GetAPIDomain, GetAPIHeaders } from "../Helper";

export class UserTypesQueryParams extends ListQueryParams {
  WithAccount(value: string) {
    this.WithParam("accountId", value);
    return this;
  }
}

export class UserTypesApiClient {
  static async List(params: UserTypesQueryParams) {
    var query =
      (await GetAPIDomain()) + "/user-types" + params.GenerateQueryString();

    return await ApiResponse.Create(
      await fetch(query, {
        headers: await GetAPIHeaders(),
      })
    );
  }
}
