import React, { Component, useContext } from "react";
import { Redirect, Link } from "react-router-dom";
import { Spinner, Card, CardBody, Alert, Button } from "reactstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { MyDayAdminAPI } from "../infrastructure/MyDayAdminAPI";
import * as msal from "@azure/msal-browser";
import { AuthContext } from "../infrastructure/AuthContext";

const msalConfig = {
  auth: {
    clientId: "cb6c8540-e5e5-483c-8dc7-79cbf0839edf",
    authority:
      "https://login.microsoftonline.com/bbd70312-bbe8-4129-b22c-51d1b5b6eb97",
  },
};

const msalInstance = new msal.PublicClientApplication(msalConfig);

export class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = { codeStatus: "", loading: false };
    this.signIn = this.signIn.bind(this);
  }

  async signIn() {
    console.log("Sign in mounted");

    const silentRequest = {
      scopes: [
        "api://cb6c8540-e5e5-483c-8dc7-79cbf0839edf/access_as_user offline_access",
      ],
    };

    try {
      const loginResponse = await msalInstance.ssoSilent(silentRequest);

      console.log(loginResponse);
      MyDayAdminAPI.SetAccessToken(
        loginResponse.accessToken,
        loginResponse.expiresOn
      );

      this.setState({
        loading: false,
        error: null,
        next: "home",
      });
    } catch (err) {
      if (err instanceof msal.InteractionRequiredAuthError) {
        const loginResponse = await msalInstance
          .loginPopup(silentRequest)
          .catch((error) => {
            console.log("token fetch error");

            this.setState({
              loading: false,
              error: error.message,
            });
          });

        console.log(loginResponse);
        MyDayAdminAPI.SetAccessToken(
          loginResponse.accessToken,
          loginResponse.expiresOn
        );

        this.setState({
          loading: false,
          error: null,
          next: "home",
        });
      } else {
        this.setState({
          loading: false,
          error: "Sorry, something went wrong",
        });
        console.log(err);
      }
    }
  }

  renderContent() {
    return (
      <AuthContext.Consumer>
        {({ refresh }) => {
          if (this.state.next === "home") {
            return <Redirect to="/" />;
          } else {
            return (
              <>
                <Button
                  color="primary"
                  type="submit"
                  disabled={this.state.loading}
                  style={{ width: "100%" }}
                  onClick={(e) =>
                    this.signIn(e).then(() => {
                      refresh();
                    })
                  }
                >
                  {!this.state.loading && "SIGN IN"}
                  {this.state.loading && <Spinner animation="border" />}
                </Button>
                {this.state.error && (
                  <Alert color="danger">
                    <h4>We cannot sign you in right now.</h4>
                    <i>{this.state.error}</i>
                  </Alert>
                )}
              </>
            );
          }
        }}
      </AuthContext.Consumer>
    );
  }

  render() {
    return (
      <Card style={{ maxWidth: "600px", width: "100%" }}>
        <CardBody>
          <div>
            <div>
              <div className="card-body text-left">
                <h3 className="mb-4">
                  <img
                    src="/assets/images/daysie_logo.png"
                    style={{
                      height: "50px",
                      position: "relative",
                      top: "-5px",
                    }}
                  />
                  Daysie Admin
                </h3>
                <hr />

                {this.renderContent()}

                <hr />
                {!this.state.error && this.state.loading && (
                  <p>Please follow onscreen instructions</p>
                )}
                {!this.state.error && !this.state.loading && (
                  <p>Sign in using your organisation credentials</p>
                )}
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    );
  }
}
