import React from 'react';
import { Route } from 'react-router-dom';
import { SignIn } from '../components/SignIn';
import { MyDayAdminAPI } from './MyDayAdminAPI';

export const PrivateRoute = ({ component: Component, ...rest }) => {

    if (MyDayAdminAPI.IsAuthenticated()) {

        return (
            <Route {...rest} render={props => (
                <Component {...props} />
            )} />);
    } else {

        return (
            <Route {...rest} render={() => (
                <SignIn />
            )} />);
    }
};