import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Spinner, Card, CardHeader, Row, Col, CardBody } from "reactstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import {
  InvitationApiClient,
  InvitationQueryParams,
} from "../../infrastructure/MyDayClient/Invitations";
import { AccountSelector } from "../../infrastructure/AccountSelector";
import { RoleSelector } from "../../infrastructure/RoleSelector";
import { UserTypeSelector } from "../../infrastructure/UserTypeSelector";
export class InvitationsEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: false,
      next: false,
      accountId: null,
      userTypeId: null,
    };
  }

  componentDidMount() {
    this.populateData(this.props.match.params.id);
  }

  renderForm() {
    return (
      <Formik
        initialValues={{
          name: this.state.name,
          surname: this.state.surname,
          roleId: this.state.roleId,
          hasCrmAccess: this.state.hasCrmAccess,
          userTypeId: this.state.userTypeId,
        }}
        onSubmit={async (fields, { setErrors, setFieldError }) => {
          if (!fields.name) {
            setFieldError("name", "Please specify the name");
            return;
          }

          var response = await InvitationApiClient.Update(
            this.props.match.params.id,
            fields.name,
            fields.surname,
            fields.roleId ? fields.roleId.value : null,
            fields.hasCrmAccess,
            fields.userTypeId ? fields.userTypeId.value : null
          );

          if (!response.successful) {
            response.validationErrors.map((error) => {
              setFieldError(error.key, error.message);
              return {
                [error.key]: error.message,
              };
            });
          } else {
            this.setState({ next: true, id: response.data.id });
          }
        }}
      >
        {({
          errors,
          status,
          touched,
          isSubmitting,
          values,
          setFieldValue,
          setFieldTouched,
          handleChange,
          handleBlur,
          fields,
        }) => (
          <Form>
            <Row>
              <Col>
                <div className="form-group">
                  <label htmlFor="name">Name*</label>
                  <Field
                    name="name"
                    placeholder="E.g. John"
                    type="text"
                    className={
                      "bgWhite form-control" +
                      (errors.name && touched.name ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="invalid-feedback text-left"
                  />
                </div>
              </Col>
              <Col>
                <div className="form-group">
                  <label htmlFor="surname">Surname</label>
                  <Field
                    name="surname"
                    placeholder="E.g. Doe"
                    type="text"
                    className={
                      "bgWhite form-control" +
                      (errors.surname && touched.surname ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="surname"
                    component="div"
                    className="invalid-feedback text-left"
                  />
                </div>
              </Col>
            </Row>

            <h5>Access</h5>
            <hr />
            <Row>
              <Col className="align-self-center">
                {this.state.accountId && (
                  <RoleSelector
                    placeholder="Role"
                    name="roleId"
                    value={values.roleId}
                    onChange={setFieldValue}
                    accountId={this.state.accountId}
                    initialId={values.roleId ? values.roleId.value : null}
                  />
                )}
              </Col>
              <Col className="align-self-center">
                {this.state.accountId && (
                  <UserTypeSelector
                    placeholder="User Type"
                    name="userTypeId"
                    value={values.userTypeId}
                    onChange={setFieldValue}
                    accountId={this.state.accountId}
                    initialId={
                      values.userTypeId ? values.userTypeId.value : null
                    }
                  />
                )}
              </Col>
            </Row>
            <Row className="pt-3">
              <Col className="align-self-center">
                <div className="form-group">
                  <div className="form-check">
                    <Field
                      className="form-check-input"
                      type="checkbox"
                      name="hasCrmAccess"
                      id="crmAccess"
                    />
                    <label className="form-check-label" htmlFor="crmAccess">
                      Portal Access
                    </label>
                  </div>
                </div>
              </Col>
            </Row>

            <hr />
            <div className="form-group">
              <button
                type="submit"
                className="btn mr-2 btn-outline-dark mt-2"
                disabled={isSubmitting}
              >
                {!isSubmitting && "Save"}
                {isSubmitting && <Spinner animation="border" />}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }

  render() {
    if (this.state.next === true) {
      return <Redirect to={"/invitations/details/" + this.state.id} />;
    } else {
      return (
        <div>
          <Card>
            <CardHeader>
              <Row>
                <Col>
                  <h5>
                    {" "}
                    Update invitation
                    {this.state.loading && (
                      <Spinner
                        style={{
                          height: "18px",
                          width: "18px",
                          marginLeft: "10px",
                        }}
                        animation="border"
                      />
                    )}
                  </h5>
                  <span className="d-block m-t-5 text-muted">
                    Update an invitation
                  </span>
                </Col>
                <Col>
                  <div style={{ paddingRight: "10px" }}>
                    <Link
                      style={{ float: "right" }}
                      to={"/invitations/details/" + this.state.id}
                      className="btn mr-2 btn-outline-dark mt-2 mb-2"
                    >
                      Cancel
                    </Link>
                  </div>
                </Col>
              </Row>
            </CardHeader>

            <CardBody>
              {this.state.init && this.renderForm(this.state.data)}
            </CardBody>
          </Card>
        </div>
      );
    }
  }

  async populateData(id) {
    if (!this.state.init) {
      var response = await InvitationApiClient.Get(id);

      if (!response.authenticated) {
        this.setState({
          loading: false,
          authenticated: false,
        });
      } else {
        this.setState({
          loading: false,
          name: response.data.name,
          surname: response.data.surname,
          roleId: response.data.role ? { value: response.data.role.id } : null,
          hasCrmAccess: response.data.hasCrmAccess,
          accountId: response.data.accountId,
          userTypeId: response.data.userType
            ? {
                value: response.data.userType.id,
                label: response.data.userType.name,
              }
            : null,
          init: true,
        });
      }
    }
  }
}
