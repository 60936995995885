import React, { Component } from 'react';
import { AccountsTable } from './AccountsTable';

export class Accounts extends Component {

    render() {
        return (
            <AccountsTable />
        );
    }

}