import { ListQueryParams, ApiResponse } from "../MyDayAdminAPI";
import { GetAPIHeadersPatch, GetAPIDomain, GetAPIHeaders } from "../Helper";

export class TeamQueryParams extends ListQueryParams {

    WithAccount(value: string) {
        this.WithParam("accountId", value);
        return this;
    }

}


export class TeamApiClient {

    static async List(params: TeamQueryParams) {

        var query = (await GetAPIDomain()) + "/teams" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

}