import { ListQueryParams, ApiResponse } from "../MyDayAdminAPI";
import { GetAPIHeadersPatch, GetAPIDomain, GetAPIHeaders } from "../Helper";

export class AccountQueryParams extends ListQueryParams {
  WithAccount(value: string) {
    this.WithParam("accountId", value);
    return this;
  }
  WithMonth(value: string) {
    this.WithParam("month", value);
    return this;
  }
  WithYear(value: string) {
    this.WithParam("year", value);
    return this;
  }
}

export class AccountApiClient {
  static async List(params: AccountQueryParams) {
    var query =
      (await GetAPIDomain()) + "/accounts" + params.GenerateQueryString();

    return await ApiResponse.Create(
      await fetch(query, {
        headers: await GetAPIHeaders(),
      })
    );
  }

  static async Get(id: string) {
    return await ApiResponse.Create(
      await fetch((await GetAPIDomain()) + "/accounts/" + id, {
        headers: await GetAPIHeaders(),
      })
    );
  }

  static async ListUsers(id: string, params: AccountQueryParams) {
    var query =
      (await GetAPIDomain()) +
      `/accounts/${id}/users` +
      params.GenerateQueryString();

    return await ApiResponse.Create(
      await fetch(query, {
        headers: await GetAPIHeaders(),
      })
    );
  }

  static async BillingFile(params: AccountQueryParams) {
    var query =
      (await GetAPIDomain()) +
      `/accounts/billing-file` +
      params.GenerateQueryString();

    return await ApiResponse.Create(
      await fetch(query, {
        headers: await GetAPIHeaders(),
      })
    );
  }

  static async Create(
    companyName: string,
    legalName?: string,
    address1?: string,
    address2?: string,
    address3?: string,
    address4?: string,
    country?: string,
    contactName?: string,
    email?: string,
    mobile?: string,
    identityProvider?: string,
    clientId?: string,
    tenantId?: string,
    groupSyncEnabled?: boolean,
    templateAccountId?: string,
    externalBilling?: string
  ) {
    return await ApiResponse.Create(
      await fetch((await GetAPIDomain()) + "/accounts/", {
        method: "post",
        headers: await GetAPIHeaders(),
        body: JSON.stringify({
          companyName: companyName,
          legalName: legalName,
          address1: address1,
          address2: address2,
          address3: address3,
          address4: address4,
          country: country,
          contactName: contactName,
          email: email,
          mobile: mobile,
          identityProvider: identityProvider,
          clientId: clientId,
          tenantId: tenantId,
          groupSyncEnabled: groupSyncEnabled,
          templateAccountId: templateAccountId,
          externalBilling: externalBilling,
        }),
      })
    );
  }

  static async Update(
    id: string,
    companyName: string,
    legalName?: string,
    address1?: string,
    address2?: string,
    address3?: string,
    address4?: string,
    country?: string,
    contactName?: string,
    email?: string,
    mobile?: string,
    identityProvider?: string,
    clientId?: string,
    tenantId?: string,
    groupSyncEnabled?: boolean,
    status?: string,
    externalBilling?: string
  ) {
    return await ApiResponse.Create(
      await fetch((await GetAPIDomain()) + "/accounts/" + id, {
        method: "PATCH",
        headers: await GetAPIHeadersPatch(),
        body: JSON.stringify([
          {
            op: "replace",
            path: "companyName",
            value: companyName,
          },
          {
            op: "replace",
            path: "legalName",
            value: legalName,
          },
          {
            op: "replace",
            path: "address1",
            value: address1,
          },
          {
            op: "replace",
            path: "address2",
            value: address2,
          },
          {
            op: "replace",
            path: "address3",
            value: address3,
          },
          {
            op: "replace",
            path: "address4",
            value: address4,
          },
          {
            op: "replace",
            path: "country",
            value: country,
          },
          {
            op: "replace",
            path: "contactName",
            value: contactName,
          },
          {
            op: "replace",
            path: "email",
            value: email,
          },
          {
            op: "replace",
            path: "mobile",
            value: mobile,
          },
          {
            op: "replace",
            path: "identityProvider",
            value: identityProvider,
          },
          {
            op: "replace",
            path: "clientId",
            value: clientId,
          },
          {
            op: "replace",
            path: "tenantId",
            value: tenantId,
          },
          {
            op: "replace",
            path: "groupSyncEnabled",
            value: groupSyncEnabled,
          },
          {
            op: "replace",
            path: "status",
            value: status,
          },
          {
            op: "replace",
            path: "externalBilling",
            value: externalBilling,
          },
        ]),
      })
    );
  }

  static async Delete(id: string) {
    var query = (await GetAPIDomain()) + "/accounts/" + id;

    return await ApiResponse.Create(
      await fetch(query, {
        headers: await GetAPIHeaders(),
        method: "delete",
      })
    );
  }
}
