import React, { Component } from "react";
import { SelectField } from "./SelectField";

class YearSelector extends Component {
  constructor(props) {
    super(props);
    this.state = { init: false };
  }

  async componentDidMount() {
    await this.loadData();
  }

  async loadData() {
    const { initComplete } = this.props;

    this.setState({
      init: true,
    });
    this.setState({
      options: [
        { value: 2020, label: "2020" },
        { value: 2021, label: "2021" },
        { value: 2022, label: "2022" },
        { value: 2023, label: "2023" },
        { value: 2024, label: "2024" },
        { value: 2025, label: "2025" },
        { value: 2026, label: "2026" },
        { value: 2027, label: "2027" },
        { value: 2028, label: "2028" },
        { value: 2029, label: "2029" },
        { value: 2030, label: "2030" },
      ],
    });
    if (initComplete) initComplete();
  }

  handleChange = (name, value) => {
    const { onChange } = this.props;

    this.setState({ inputValue: value });

    onChange(name, value);
  };

  render() {
    const {
      id,
      name,
      label,
      placeholder,
      value,
      isMulti,
      isDisabled,
      touched,
      error,
      onBlur,
      hideLabel,
    } = this.props;

    return (
      this.state.init && (
        <SelectField
          id={id ? id : "year"}
          name={name ? name : "year"}
          label={label ? label : hideLabel ? null : "Year"}
          placeholder={placeholder ? placeholder : "Select year"}
          options={this.state.options}
          value={value}
          onChange={this.handleChange}
          onBlur={onBlur}
          touched={touched}
          isMulti={isMulti}
          error={error}
          isDisabled={isDisabled}
          isClearable={true}
          backspaceRemovesValue={true}
        />
      )
    );
  }
}

export { YearSelector };
