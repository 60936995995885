import { ListQueryParams, ApiResponse } from "../MyDayAdminAPI";
import { GetAPIHeadersPatch, GetAPIDomain, GetAPIHeaders } from "../Helper";

export class RoleQueryParams extends ListQueryParams {

    WithAccount(value: string) {
        this.WithParam("accountId", value);
        return this;
    }

}


export class RoleApiClient {

    static async List(params: RoleQueryParams) {

        var query = (await GetAPIDomain()) + "/roles" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

}