import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Spinner,
  Card,
  CardHeader,
  Row,
  Col,
  CardBody,
  Button,
} from "reactstrap";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { AccountApiClient } from "../../infrastructure/MyDayClient/Accounts";
import {
  InvitationApiClient,
  InvitationQueryParams,
} from "../../infrastructure/MyDayClient/Invitations";
import {
  AccountDomainApiClient,
  AccountDomainQueryParams,
} from "../../infrastructure/MyDayClient/AccountDomains";

export class AccountsDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      loading: false,
      authenticated: true,
      next: false,
      domainData: null,
    };
  }

  async componentDidMount() {
    this.populateData(this.props.match.params.id);
  }

  render() {
    console.log(this.state.domainData);

    if (this.state.next) {
      return <Redirect to="/" />;
    } else {
      return (
        <div>
          {this.state.data && (
            <>
              <Card>
                <CardHeader>
                  <Row>
                    <Col>
                      <h5>
                        <Link
                          to="/"
                          className="btn mr-2 btn-outline-dark mt-2 mb-2"
                        >
                          <FontAwesomeIcon icon={faChevronLeft} />
                        </Link>
                        {this.state.data && <span>Accounts</span>}
                        {this.state.loading && (
                          <Spinner
                            style={{
                              height: "18px",
                              width: "18px",
                              marginLeft: "10px",
                            }}
                            animation="border"
                          />
                        )}
                      </h5>
                    </Col>
                    <Col>
                      <Link
                        style={{ float: "right" }}
                        to={"/accounts/edit/" + this.state.data.id}
                        className="btn mr-2 btn-outline-dark mt-2 mb-2"
                      >
                        Edit
                      </Link>
                    </Col>
                  </Row>
                </CardHeader>
              </Card>

              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <h6>Details</h6>
                      <hr />

                      <table>
                        <tbody>
                          <tr>
                            <td className="pr-2">Company Name:</td>
                            <td>
                              {this.state.data.companyName &&
                                this.state.data.companyName}
                              {!this.state.data.companyName && (
                                <i>Not specified</i>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td className="pr-2">Legal Name:</td>
                            <td>
                              {this.state.data.legalName &&
                                this.state.data.legalName}
                              {!this.state.data.legalName && (
                                <i>Not specified</i>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td className="pr-2">Status:</td>
                            <td>
                              {this.state.data.status && this.state.data.status}
                              {!this.state.data.status && <i>Not specified</i>}
                            </td>
                          </tr>
                          <tr>
                            <td className="pr-2">Contact Name:</td>
                            <td>
                              {this.state.data.contactName &&
                                this.state.data.contactName}
                              {!this.state.data.contactName && (
                                <i>Not specified</i>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td className="pr-2">Email:</td>
                            <td>
                              {this.state.data.email && this.state.data.email}
                              {!this.state.data.email && <i>Not specified</i>}
                            </td>
                          </tr>
                          <tr>
                            <td className="pr-2">Mobile:</td>
                            <td>
                              {this.state.data.mobile && this.state.data.mobile}
                              {!this.state.data.mobile && <i>Not specified</i>}
                            </td>
                          </tr>

                          <tr>
                            <td className="pr-2">Address:</td>
                            <td>
                              {this.state.data.address1 &&
                                this.state.data.address1}
                              {this.state.data.address1 && <br />}
                              {this.state.data.address2 &&
                                this.state.data.address2}
                              {this.state.data.address2 && <br />}
                              {this.state.data.address3 &&
                                this.state.data.address3}
                              {this.state.data.address3 && <br />}
                              {this.state.data.address4 &&
                                this.state.data.address4}
                              {this.state.data.address4 && <br />}
                            </td>
                          </tr>
                          <tr>
                            <td className="pr-2">Country:</td>
                            <td>
                              {this.state.data.country &&
                                this.state.data.country}
                              {!this.state.data.country && <i>Not specified</i>}
                            </td>
                          </tr>
                          <tr>
                            <td className="pr-2">Identity Provider:</td>
                            <td>
                              {this.state.data.identityProvider &&
                                this.state.data.identityProvider}
                            </td>
                          </tr>

                          <tr>
                            <td className="pr-2">External Billing:</td>
                            <td>
                              {this.state.data.externalBilling &&
                                this.state.data.externalBilling}
                            </td>
                          </tr>
                          {this.state.data.identityProvider != "None" && (
                            <>
                              <tr>
                                <td className="pr-2">Tenant Id:</td>
                                <td>
                                  {this.state.data.tenantId &&
                                    this.state.data.tenantId}
                                </td>
                              </tr>
                              <tr>
                                <td className="pr-2">Client Id:</td>
                                <td>
                                  {this.state.data.clientId &&
                                    this.state.data.clientId}
                                </td>
                              </tr>
                              <tr>
                                <td className="pr-2">Group Sync Enabled:</td>
                                <td>
                                  {this.state.data.groupSyncEnabled && "True"}
                                  {!this.state.data.groupSyncEnabled && "False"}
                                </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </table>
                    </Col>

                    <Col>
                      <h6>Configuration</h6>
                      <hr />
                      {this.state.domainData &&
                        this.state.domainData.data.length > 0 && (
                          <table>
                            {this.state.domainData.data.map((item) => (
                              <tr>
                                <td>{item.domain}</td>
                              </tr>
                            ))}
                          </table>
                        )}

                      {this.state.domainData &&
                        this.state.domainData.data.length == 0 && (
                          <i>No domains configured</i>
                        )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </>
          )}
        </div>
      );
    }
  }

  async populateData(id) {
    if (!this.state.loading) {
      this.setState({ loading: true });

      var response = await AccountApiClient.Get(id);

      var query = new AccountDomainQueryParams()
        .Paginate(0, 100)
        .WithAccount(id);

      var domains = await AccountDomainApiClient.List(query);

      console.log(domains.data);

      if (!response.authenticated) {
        this.setState({
          authenticated: false,
          loading: false,
        });
      } else {
        this.setState({
          data: response.data,
          domainData: domains.data,
          loading: false,
        });
      }
    }
  }
}
