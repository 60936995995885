import { ListQueryParams, ApiResponse } from "../MyDayAdminAPI";
import { GetAPIHeadersPatch, GetAPIDomain, GetAPIHeaders } from "../Helper";

export class AccountDomainQueryParams extends ListQueryParams {

    WithAccount(value: string) {
        this.WithParam("accountId", value);
        return this;
    }

}


export class AccountDomainApiClient {

    static async List(params: AccountDomainQueryParams) {

        var query = (await GetAPIDomain()) + "/account-domains" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/account-domains/" + id, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Create(
        domain: string,
        accountId: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/account-domains/", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                "domain": domain,
                "accountId": accountId
            })
        }));
    }

    static async Update(
        id: string,
        domain: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/account-domains/" + id, {
            method: 'PATCH',
            headers: await GetAPIHeadersPatch(),
            body: JSON.stringify([{
                "op": "replace",
                "path": "domain",
                "value": domain
            }])
        }));
    }

    static async Delete(id: string) {

        var query = (await GetAPIDomain()) + "/account-domains/" + id;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'DELETE'
        }));
    }
}