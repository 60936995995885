import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import {
  Spinner,
  Card,
  CardHeader,
  Row,
  Col,
  CardBody,
  Input,
  InputGroup,
  ButtonGroup,
  Badge,
} from "reactstrap";
import {
  InvitationApiClient,
  InvitationQueryParams,
} from "../../infrastructure/MyDayClient/Invitations";
import { TableHeader } from "../../infrastructure/TableHeader";
import Moment from "react-moment";
import { AccountSelector } from "../../infrastructure/AccountSelector";
import { RoleSelector } from "../../infrastructure/RoleSelector";
import { TeamSelector } from "../../infrastructure/TeamSelector";

export class InvitationsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: false,
      total: 0,
      skip: 0,
      take: 50,
      search: "",
      sort: "created",
      direction: "desc",
      accountId: null,
      roleId: null,
      teamId: null,
    };
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }

  handleSearchChange(evt) {
    this.setState({
      search: evt.target.value,
    });
  }

  async clear() {
    this.setState(
      {
        search: "",
        skip: 0,
        accountId: null,
        teamId: null,
        roleId: null,
      },
      () => this.update()
    );
  }

  componentDidMount() {
    this.update();
  }

  async update() {
    await this.populateData(this.state.skip, this.state.take);
  }

  renderTable(data) {
    var columns = [
      { name: "First name", sort: "name" },
      { name: "Surname", sort: "surname" },
      { name: "Email", sort: "email" },
      { name: "Role", sort: "role.name" },
      //   { name: "User Type", sort: "userType.name" },
      { name: "Team", sort: "team.name" },
      { name: "Status", sort: "status" },
      { name: "Expiry", sort: "expiry" },
      { name: "Accepted By", sort: "user.name" },
      { name: "Created", sort: "created" },
      { name: "", sort: "", static: true },
    ];

    return (
      <div className="table-responsive">
        <table className="table" aria-labelledby="tabelLabel">
          <TableHeader
            sort={this.state.sort}
            direction={this.state.direction}
            columns={columns}
            onUpdate={(sort, direction) =>
              this.setState(
                {
                  sort: sort,
                  direction: direction,
                },
                () => this.update()
              )
            }
          />
          <tbody>
            {data.map((item) => (
              <tr key={item.id}>
                <td>
                  {item.name && <span>{item.name}</span>}
                  {!item.name && <i>Name not specified</i>}
                </td>
                <td>
                  {item.surname && <span>{item.surname}</span>}
                  {!item.surname && <i>Name not specified</i>}
                </td>
                <td>
                  {item.email && item.email}
                  {!item.email && <i>Not specified</i>}
                </td>
                {/* <td>
                  {item.userType && item.userType.name}
                  {!item.userType && <i>None</i>}
                </td> */}
                <td>
                  {item.role && item.role.name}
                  {!item.role && <i>None</i>}
                </td>
                <td>
                  {item.team && item.team.name}
                  {!item.team && <i>None</i>}
                </td>
                <td>{item.status}</td>
                <td>
                  {item.expiry && (
                    <Moment utc local format="ddd DD MMM, h:mm a">
                      {item.expiry}
                    </Moment>
                  )}
                  {!item.expiry && <i>Never</i>}
                </td>
                <td>
                  {item.user && item.user.name}
                  {!item.user && <i>N/A</i>}
                </td>
                <td>
                  {item.created && (
                    <Moment utc local format="ddd DD MMM, h:mm a">
                      {item.created}
                    </Moment>
                  )}
                </td>
                <td>
                  <Link
                    key={item.id + "-view"}
                    style={{ float: "right" }}
                    className="btn mr-2 btn-outline-dark mt-2"
                    to={"/invitations/details/" + item.id}
                  >
                    Details
                  </Link>
                  <Link
                    key={item.id + "-edit"}
                    style={{ float: "right" }}
                    className="btn mr-2 btn-outline-dark mt-2"
                    to={"/invitations/edit/" + item.id}
                  >
                    Edit
                  </Link>
                </td>
              </tr>
            ))}
            {this.state.data.length === 0 && (
              <tr>
                <td colSpan="8">
                  <h4 className="text-muted text-center mt-3">
                    <i>No invitations to display</i>
                  </h4>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  }

  render() {
    const { hideFilter, description } = this.props;

    return (
      <div>
        <Card>
          <CardHeader>
            <Row>
              <Col>
                <h5>
                  {" "}
                  Invitations ({this.state.total})
                  {this.state.loading && (
                    <Spinner
                      style={{
                        height: "18px",
                        width: "18px",
                        marginLeft: "10px",
                      }}
                      animation="border"
                    />
                  )}
                </h5>
                <span className="d-block m-t-5 text-muted">
                  {description}{" "}
                  {!description && "List of invitations in the system"}
                </span>
              </Col>
              <Col>
                <div style={{ paddingRight: "10px" }}>
                  <Link
                    style={{ float: "right" }}
                    to="/invitations/new"
                    className="btn mr-2 btn-outline-dark mt-2 mb-2"
                  >
                    Invite
                  </Link>
                  <button
                    style={{ float: "right" }}
                    onClick={() => {
                      this.next();
                    }}
                    disabled={
                      this.state.loading ||
                      this.state.skip + this.state.take >= this.state.total
                    }
                    className="btn mr-2 btn-outline-dark mt-2 mb-2"
                  >
                    Next
                  </button>
                  <button
                    style={{ float: "right" }}
                    onClick={() => {
                      this.previous();
                    }}
                    disabled={this.state.loading || this.state.skip <= 0}
                    className="btn mr-2 btn-outline-dark mt-2 mb-2"
                  >
                    Prev
                  </button>
                </div>
              </Col>
            </Row>
          </CardHeader>
        </Card>
        <Card>
          {!hideFilter && (
            <CardHeader>
              <Row>
                <Col>
                  <h5>Filter</h5>
                </Col>
                <Col>
                  <Row>
                    <Col>
                      <div style={{ paddingRight: "10px" }}>
                        <AccountSelector
                          hideLabel={true}
                          placeholder="Filter to account"
                          name="accountId"
                          value={this.state.accountId}
                          onChange={(name, value) =>
                            this.setState(
                              { accountId: value, roleId: null, teamId: null },
                              () => this.update()
                            )
                          }
                        />
                      </div>
                    </Col>
                    <Col>
                      <div style={{ paddingRight: "10px" }}>
                        <RoleSelector
                          hideLabel={true}
                          accountId={
                            this.state.accountId
                              ? this.state.accountId.value
                              : null
                          }
                          placeholder="Filter to role"
                          name="roleId"
                          value={this.state.roleId}
                          onChange={(name, value) =>
                            this.setState({ roleId: value }, () =>
                              this.update()
                            )
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <Col>
                      <div style={{ paddingRight: "10px" }}>
                        <TeamSelector
                          hideLabel={true}
                          accountId={
                            this.state.accountId
                              ? this.state.accountId.value
                              : null
                          }
                          placeholder="Filter to team"
                          name="teamId"
                          value={this.state.teamId}
                          onChange={(name, value) =>
                            this.setState({ teamId: value }, () =>
                              this.update()
                            )
                          }
                        />
                      </div>
                    </Col>
                    <Col>
                      <div style={{ paddingRight: "10px" }}>
                        <InputGroup>
                          <Input
                            type="text"
                            placeholder="Search"
                            value={this.state.search}
                            onChange={this.handleSearchChange}
                            onKeyPress={(e) =>
                              e.key === "Enter" && this.update()
                            }
                          />
                          <ButtonGroup className="input-group-append">
                            <button
                              onClick={() => {
                                this.update();
                              }}
                              disabled={this.state.loading}
                              className="btn btn-outline-dark"
                            >
                              <span className="pcoded-micon">
                                <i className="feather icon-search"></i>
                              </span>
                            </button>
                          </ButtonGroup>
                          <ButtonGroup>
                            {(this.state.search ||
                              this.state.accountId ||
                              this.state.teamId ||
                              this.state.roleId) && (
                              <button
                                onClick={() => {
                                  this.clear();
                                }}
                                className="btn btn-outline-dark ml-2"
                              >
                                <span className="pcoded-micon">Clear</span>
                              </button>
                            )}
                          </ButtonGroup>
                        </InputGroup>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardHeader>
          )}

          <CardBody className="p-0">
            {this.state.data &&
              this.renderTable(
                this.state.data,
                this.state.nextPage,
                this.state.previousPage
              )}
          </CardBody>
        </Card>
      </div>
    );
  }

  async previous() {
    await this.populateData(this.state.skip - this.state.take, this.state.take);
  }

  async next() {
    await this.populateData(this.state.skip + this.state.take, this.state.take);
  }

  async populateData(skip, take) {
    if (!this.state.loading) {
      this.setState({ loading: true, skip: skip, take: take });

      var query = new InvitationQueryParams()
        .Paginate(skip, take)
        .Search(this.state.search);

      if (this.state.accountId)
        query = query.WithAccount(this.state.accountId.value);

      if (this.state.roleId) query = query.WithRole(this.state.roleId.value);

      if (this.state.teamId) query = query.WithTeam(this.state.teamId.value);

      var response = await InvitationApiClient.List(
        query.Sort(this.state.sort, this.state.direction)
      );

      this.setState({
        data: response.data.data,
        loading: false,
        total: response.data.total,
      });
    }
  }
}
