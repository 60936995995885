import React, { Component, useContext } from "react";
import { Route } from "react-router";
import { PrivateRoute } from "./infrastructure/PrivateRoute";
import { AuthContext } from "./infrastructure/AuthContext";

import { Layout } from "./components/Layout/Layout";
import { SignIn } from "./components/SignIn";

import "./custom.css";
import { MyDayAdminAPI } from "./infrastructure/MyDayAdminAPI";

import { Accounts } from "./components/Accounts/Accounts";
import { AccountsNew } from "./components/Accounts/AccountsNew";
import { AccountsEdit } from "./components/Accounts/AccountsEdit";
import { AccountsDetails } from "./components/Accounts/AccountsDetails";

import { Invitations } from "./components/Invitations/Invitations";
import { InvitationsNew } from "./components/Invitations/InvitationsNew";
import { InvitationsEdit } from "./components/Invitations/InvitationsEdit";
import { InvitationDetails } from "./components/Invitations/InvitationDetails";

import { AccountDomains } from "./components/AccountDomains/AccountDomains";
import { AccountDomainsNew } from "./components/AccountDomains/AccountDomainsNew";
import { AccountDomainsEdit } from "./components/AccountDomains/AccountDomainsEdit";
import { AccountDomainDetails } from "./components/AccountDomains/AccountDomainDetails";

import { AccountUsers } from "./components/AccountUsers/AccountUsers";
import { ExportBillingFile } from "./components/ExportBillingFile/ExportBillingFile";

export default class App extends Component {
  static displayName = App.name;

  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: MyDayAdminAPI.IsAuthenticated(),
    };

    this.refreshApplicationState = this.refreshApplicationState.bind(this);
  }

  async componentDidMount() {
    await this.refreshApplicationState();
  }

  async refreshApplicationState() {
    this.setState({
      isAuthenticated: MyDayAdminAPI.IsAuthenticated(),
    });
  }

  render() {
    const value = {
      isAuthenticated: this.state.isAuthenticated,
      refresh: this.refreshApplicationState,
    };

    return (
      <AuthContext.Provider value={value}>
        <Layout>
          <PrivateRoute exact path="/" component={Accounts} />
          <PrivateRoute exact path="/accounts/create" component={AccountsNew} />
          <PrivateRoute
            exact
            path="/accounts/edit/:id"
            component={AccountsEdit}
          />
          <PrivateRoute
            exact
            path="/accounts/details/:id"
            component={AccountsDetails}
          />
          <PrivateRoute exact path="/invitations" component={Invitations} />
          <PrivateRoute
            exact
            path="/invitations/new"
            component={InvitationsNew}
          />
          <PrivateRoute
            exact
            path="/invitations/edit/:id"
            component={InvitationsEdit}
          />
          <PrivateRoute
            exact
            path="/invitations/details/:id"
            component={InvitationDetails}
          />
          <PrivateRoute
            exact
            path="/account-domains"
            component={AccountDomains}
          />
          <PrivateRoute
            exact
            path="/account-domains/new"
            component={AccountDomainsNew}
          />
          <PrivateRoute
            exact
            path="/account-domains/edit/:id"
            component={AccountDomainsEdit}
          />
          <PrivateRoute
            exact
            path="/account-domains/details/:id"
            component={AccountDomainDetails}
          />
          <PrivateRoute exact path="/account-users" component={AccountUsers} />
          <PrivateRoute
            exact
            path="/export-billing-file"
            component={ExportBillingFile}
          />
        </Layout>
      </AuthContext.Provider>
    );
  }
}
