import React, { Component } from 'react';
import { AccountDomainsTable } from './AccountDomainsTable';

export class AccountDomains extends Component {

    render() {
        return (
            <AccountDomainsTable />
        );
    }

}