import React, { Component } from "react";
import { SelectField } from "./SelectField";

class MonthSelector extends Component {
  constructor(props) {
    super(props);
    this.state = { init: false };
  }

  async componentDidMount() {
    await this.loadData();
  }

  async loadData() {
    const { initComplete } = this.props;

    this.setState({
      init: true,
    });
    this.setState({
      options: [
        { value: 1, label: "January" },
        { value: 2, label: "February" },
        { value: 3, label: "March" },
        { value: 4, label: "April" },
        { value: 5, label: "May" },
        { value: 6, label: "June" },
        { value: 7, label: "July" },
        { value: 8, label: "August" },
        { value: 9, label: "September" },
        { value: 10, label: "October" },
        { value: 11, label: "November" },
        { value: 12, label: "December" },
      ],
    });
    if (initComplete) initComplete();
  }

  handleChange = (name, value) => {
    const { onChange } = this.props;

    this.setState({ inputValue: value });

    onChange(name, value);
  };

  render() {
    const {
      id,
      name,
      label,
      placeholder,
      value,
      isMulti,
      isDisabled,
      touched,
      error,
      onBlur,
      hideLabel,
    } = this.props;

    return (
      this.state.init && (
        <SelectField
          id={id ? id : "month"}
          name={name ? name : "month"}
          label={label ? label : hideLabel ? null : "month"}
          placeholder={placeholder ? placeholder : "Select month"}
          options={this.state.options}
          value={value}
          onChange={this.handleChange}
          onBlur={onBlur}
          touched={touched}
          isMulti={isMulti}
          error={error}
          isDisabled={isDisabled}
          isClearable={true}
          backspaceRemovesValue={true}
        />
      )
    );
  }
}

export { MonthSelector };
