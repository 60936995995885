import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { AccountDomainApiClient, AccountDomainQueryParams } from '../../infrastructure/MyDayClient/AccountDomains';

export class AccountDomainsEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [], loading: false, next: false, accountId: null
        };

    }

    componentDidMount() {
        this.populateData(this.props.match.params.id);
    }

    renderForm() {
        return (<Formik
            initialValues={{
                domain: this.state.domain,
                accountId: this.state.accountId,
            }}
            onSubmit={async (fields, { setErrors, setFieldError }) => {

                if (!fields.domain) {
                    setFieldError("domain", "Please specify the domain");
                    return;
                }

                var response = await AccountDomainApiClient.Update(
                    this.props.match.params.id,
                    fields.domain
                );

                if (!response.successful) {

                    response.validationErrors.map(error => {
                        setFieldError("domain", error.message);
                        return {
                            ["domain"]: error.message
                        };
                    });

                } else {
                    this.setState({ next: true, id: response.data.id });
                }

            }}
        >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur, fields }) => (
            <Form>
                <Row>
                    <Col>
                        <div className="form-group">
                                <label htmlFor="domain">Domain*</label>
                                <Field name="domain" placeholder="E.g. seveno.nz" type="text" className={'bgWhite form-control' + (errors.domain && touched.domain ? ' is-invalid' : '')} />
                                <ErrorMessage name="domain" component="div" className="invalid-feedback text-left" />
                        </div>
                    </Col>
                    </Row>
                <hr />
                <div className="form-group">
                    <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                        {!isSubmitting && "Save"}
                        {isSubmitting && <Spinner animation="border" />}
                    </button>
                </div>
            </Form>
        )}</Formik>);
    }

    render() {

        if (this.state.next === true) {
            return (<Redirect to={"/account-domains/details/" + this.state.id} />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> Update account domain
                                        {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Update domain</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to={"/account-domains/details/" + this.state.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.state.init && this.renderForm(this.state.data)}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }


    async populateData(id) {
        if (!this.state.init) {

            var response = await AccountDomainApiClient.Get(id);

            if (!response.authenticated) {
                this.setState({
                    loading: false,
                    authenticated: false
                });
            } else {

                this.setState({
                    loading: false,
                    id:response.data.id,
                    domain: response.data.domain,
                    init: true
                });
            }
        }
    }
}