import React, { Component } from "react";
import { Navbar, NavbarBrand, NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import "./NavMenu.css";
import { MyDayAdminAPI } from "../../infrastructure/MyDayAdminAPI";
import { AuthContext } from "../../infrastructure/AuthContext";

export class NavMenu extends Component {
  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true,
    };
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }

  render() {
    return (
      <AuthContext.Consumer>
        {({ refresh, isAuthenticated }) => {
          return (
            <header>
              <Navbar
                className="navbar navbar-dark bg-dark flex-md-nowrap shadow"
                dark
                style={{ paddingTop: "10px", paddingBottom: "10px" }}
              >
                <NavbarBrand
                  style={{ textAlign: "left" }}
                  className="navbar-brand col-sm-3 col-md-2 bg-dark"
                  tag={Link}
                  to="/"
                >
                  <img
                    src="/assets/images/daysie_logo.png"
                    style={{
                      height: "50px",
                      position: "relative",
                      top: "-5px",
                    }}
                  />
                  Daysie
                </NavbarBrand>

                <ul className="navbar-nav px-3 flex-row">
                  <NavItem>
                    <NavLink tag={Link} className="pr-3" to="/accounts/create">
                      <strong>Add Account</strong>
                    </NavLink>
                  </NavItem>

                  {isAuthenticated && (
                    <NavItem>
                      <NavLink
                        tag={Link}
                        className="text-dark"
                        to="/"
                        onClick={() => {
                          MyDayAdminAPI.LogOut();
                          refresh();
                        }}
                      >
                        Sign out
                      </NavLink>
                    </NavItem>
                  )}

                  {!isAuthenticated && (
                    <NavItem>
                      <NavLink tag={Link} className="text-dark" to="/sign-in">
                        Sign in
                      </NavLink>
                    </NavItem>
                  )}
                </ul>
              </Navbar>
            </header>
          );
        }}
      </AuthContext.Consumer>
    );
  }
}
