import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import {
  Spinner,
  Card,
  CardHeader,
  Row,
  Col,
  CardBody,
  Input,
  InputGroup,
  ButtonGroup,
  Badge,
} from "reactstrap";
import {
  AccountApiClient,
  AccountQueryParams,
} from "../../infrastructure/MyDayClient/Accounts";
import { TableHeader } from "../../infrastructure/TableHeader";
import Moment from "react-moment";
import { AccountSelector } from "../../infrastructure/AccountSelector";
import { YearSelector } from "../../infrastructure/YearSelector";
import { MonthSelector } from "../../infrastructure/MonthSelector";
var XLSX = require("xlsx");

export class ExportBillingFileTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: false,
      total: 0,
      skip: 0,
      take: 50,
      search: "",
      sort: "companyName",
      direction: "asc",
      showSuspended: false,
      status: "",
      accountId: "",
      year: null,
      month: null,
      showExport: false,
    };
  }

  async clear() {
    this.setState(
      {
        search: "",
        skip: 0,
        accountId: "",
        year: null,
        month: null,
        showExport: false,
      },
      () => this.update()
    );
  }

  async update() {
    await this.populateData(this.state.skip, this.state.take);
  }

  downloadxls = () => {
    var mappedData = [];

    mappedData = this.state.data.map((item) => ({
      externalBillingNumber: item.externalBillingNumber,
      accountName: item.accountName,
      usersCount: item.usersCount,
      total: item.total,
      month: item.month,
      year: item.year,
    }));

    let ws = XLSX.utils.json_to_sheet(mappedData);
    let wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "sheet");
    let buf = XLSX.write(wb, { bookType: "xlsx", type: "buffer" }); // generate a nodejs buffer
    let str = XLSX.write(wb, { bookType: "xlsx", type: "binary" }); // generate a binary string in web browser
    XLSX.writeFile(
      wb,
      `Billing_${this.state.month.label}_${this.state.year.value}.xlsx`
    );
  };

  renderTable(data) {
    var columns = [
      { name: "External Billing No.", static: true },
      { name: "Account Name", static: true },
      { name: "Active Users Count", static: true },
      { name: "Deleted Users Count", static: true },
      { name: "Total Users", static: true },
      { name: "Month", static: true },
      { name: "Year", static: true },
      { name: "", sort: "", static: true },
    ];

    return (
      <div className="table-responsive">
        <table className="table" aria-labelledby="tabelLabel">
          <TableHeader
            sort={this.state.sort}
            direction={this.state.direction}
            columns={columns}
            onUpdate={(sort, direction) =>
              this.setState(
                {
                  sort: sort,
                  direction: direction,
                },
                () => this.update()
              )
            }
          />
          <tbody>
            {data.map((item) => (
              <tr key={item.id}>
                <td>{item.externalBillingNumber}</td>
                <td>{item.accountName}</td>
                <td>{item.usersCount}</td>
                <td>{item.deletedCount}</td>
                <td>{item.total}</td>
                <td>{item.month}</td>
                <td>{item.year}</td>
              </tr>
            ))}
            {this.state.data.length === 0 && (
              <tr>
                <td colSpan="8">
                  <h4 className="text-muted text-center mt-3">
                    <i>No data to display</i>
                  </h4>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  }

  render() {
    const { hideFilter, description } = this.props;

    const { year, month, accountId } = this.state;
    return (
      <div>
        <Card>
          <CardHeader>
            <Row>
              <Col>
                <h5>
                  {" "}
                  Export Billing File ({this.state.data?.length || 0})
                  {this.state.loading && (
                    <Spinner
                      style={{
                        height: "18px",
                        width: "18px",
                        marginLeft: "10px",
                      }}
                      animation="border"
                    />
                  )}
                </h5>
                <span className="d-block m-t-5 text-muted">
                  {description} {!description && "Billing summary per account"}
                </span>
              </Col>
              <Col>
                <div style={{ paddingRight: "10px" }}>
                  <button
                    onClick={() => {
                      this.downloadxls();
                    }}
                    className="btn btn-outline-dark ml-2"
                    style={{ float: "right" }}
                    disabled={!this.state.showExport}
                  >
                    <span className="pcoded-micon">Export</span>
                  </button>
                </div>
              </Col>
            </Row>
          </CardHeader>
        </Card>
        <Card>
          {!hideFilter && (
            <>
              <CardHeader>
                <Row>
                  <Col>
                    <h5>Filter</h5>
                  </Col>

                  <Col>
                    <MonthSelector
                      hideLabel={true}
                      placeholder="Select Month"
                      name="month"
                      value={this.state.month}
                      onChange={(name, value) =>
                        this.setState({ month: value })
                      }
                    />
                  </Col>
                  <Col>
                    <YearSelector
                      hideLabel={true}
                      placeholder="Select Year"
                      name="year"
                      value={this.state.year}
                      onChange={(name, value) => this.setState({ year: value })}
                    />
                  </Col>
                  <Col>
                    <AccountSelector
                      hideLabel={true}
                      placeholder="Filter to account"
                      name="accountId"
                      value={this.state.accountId}
                      onChange={(name, value) =>
                        this.setState({ accountId: value })
                      }
                    />
                  </Col>
                  <Col>
                    <button
                      onClick={() => {
                        this.update();
                      }}
                      className="btn btn-outline-dark ml-2"
                      style={{ float: "right" }}
                      disabled={!year || !month}
                    >
                      <span className="pcoded-micon">Search</span>
                    </button>{" "}
                  </Col>
                </Row>
              </CardHeader>
            </>
          )}

          <CardBody className="p-0">
            {this.state.data &&
              this.renderTable(
                this.state.data,
                this.state.nextPage,
                this.state.previousPage
              )}
          </CardBody>
        </Card>
      </div>
    );
  }

  async previous() {
    await this.populateData(this.state.skip - this.state.take, this.state.take);
  }

  async next() {
    await this.populateData(this.state.skip + this.state.take, this.state.take);
  }

  async populateData(skip, take) {
    if (!this.state.loading) {
      this.setState({ loading: true, skip: skip, take: take });

      const { year, month, accountId } = this.state;

      console.log(this.state);

      var query = new AccountQueryParams()
        .WithYear(year.value)
        .WithMonth(month.value);

      if (accountId) query.WithAccount(accountId.value);

      var response = await AccountApiClient.BillingFile(query);

      this.setState({
        data: response.data,
        loading: false,
        showExport: true,
      });
    }
  }
}
