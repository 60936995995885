import React, { Component } from "react";
import Select from "react-select";

class SelectField extends Component {
    handleChange = value => {
        const { onChange, name } = this.props;

        onChange(name, value);
    };

    handleBlur = () => {
        const { onBlur, name } = this.props;

        if (onBlur) {
            onBlur(name, true);
        }
    };

    render() {
        const {
            id,
            name,
            label,
            placeholder,
            options,
            value,
            isMulti,
            isDisabled,
            touched,
            error,
            isClearable,
            backspaceRemovesValue,
            clearOption
        } = this.props;

        var valueToSet = value;
        var optionsToUse = [];
        if (options)
            optionsToUse = options.slice();

        if (clearOption) {

            var blank = {
                value: "",
                label: clearOption
            };

            optionsToUse.splice(0, 0, blank);

            if (!valueToSet) {
                console.log("Disabling value");
                valueToSet = blank;
            }
        }

        return (
            <div className="input-field-wrapper">
                {label && (
                    <label className="input-label" htmlFor={name} error={error}>
                        {label}
                    </label>
                )}



                <Select
                    id={id}
                    placeholder={placeholder}
                    options={optionsToUse}
                    value={valueToSet}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    touched={touched}
                    error={error}
                    isMulti={isMulti}
                    isDisabled={isDisabled}
                    isClearable={isClearable}
                    backspaceRemovesValue={backspaceRemovesValue}
                    components={{ ClearIndicator: null }}
                />

                {touched && error ? <p className="error-text">{error}</p> : null}
            </div>
        );
    }
}

export { SelectField };
