import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { TabContent, TabPane, Nav, NavItem, NavLink, Spinner, Card, CardHeader, Row, Col, CardBody, Button } from 'reactstrap';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import Moment from 'react-moment';
import { AccountDomainApiClient } from '../../infrastructure/MyDayClient/AccountDomains';

export class AccountDomainDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: null, loading: false, authenticated: true, next: false,
        };
        this.delete = this.delete.bind(this);
    }

    async componentDidMount() {
        this.populateData(this.props.match.params.id);
    }

    render() {

        if (this.state.next) {
            return (<Redirect to="/account-domains" />);
        } else {
            return (

                <div>
                    {this.state.data && <><Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>
                                        <Link to="/account-domains" className="btn mr-2 btn-outline-dark mt-2 mb-2"><FontAwesomeIcon icon={faChevronLeft} /></Link>
                                        {this.state.data && <span>Account Domains</span>}
                                        {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                </Col>
                                <Col>
                                    <Link style={{ float: "right" }} to={"/account-domains/edit/" + this.state.data.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Edit</Link>
                                </Col>
                            </Row>

                        </CardHeader>
                    </Card>

                        <Card>
                            <CardBody>
                                <Row>
                                    <Col>
                                        <h6>Details</h6>
                                        <hr />

                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td className="pr-2">Domain:</td>
                                                    <td>
                                                        {this.state.data.domain && this.state.data.domain}
                                                        {!this.state.data.domain && <i>Not specified</i>}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="pr-2">Account:</td>
                                                    <td>
                                                        {this.state.data.account && this.state.data.account.companyName}
                                                        {!this.state.data.account && <i>Not specified</i>}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="pr-2">Created:</td>
                                                    <td>
                                                        {this.state.data.created && <Moment utc local format="ddd DD MMM, h:mm a">{this.state.data.created}</Moment>}
                                                    </td>
                                                </tr>
                                               
                                            </tbody>
                                        </table>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </>}

                </div>
            );
        }
    }


    async delete() {
        var success = await AccountDomainApiClient.Delete(this.props.match.params.id);
        if (success) {
            this.setState({ next: true });
        } else {
            this.setState({ deleteFail: true });
        }
    }

    async populateData(id) {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var response = await AccountDomainApiClient.Get(id);

            console.log(response.data);

            if (!response.authenticated) {
                this.setState({
                    authenticated: false,
                    loading: false
                });
            } else {
                this.setState({ data: response.data, loading: false });
            }
        }
    }

}
