import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody, Input, InputGroup, ButtonGroup, Badge } from 'reactstrap';
import { AccountDomainApiClient, AccountDomainQueryParams } from '../../infrastructure/MyDayClient/AccountDomains';
import { TableHeader } from '../../infrastructure/TableHeader';
import Moment from 'react-moment';

export class AccountDomainsTable extends Component {

    constructor(props) {
        super(props);
        this.state = { data: [], loading: false, total: 0, skip: 0, take: 50, search: "", sort: "created", direction: "desc" };
        this.handleSearchChange = this.handleSearchChange.bind(this);
    }

    handleSearchChange(evt) {
        this.setState({
            search: evt.target.value
        });
    }

    async clear() {
        this.setState({
            search: "",
            skip: 0
        }, () => this.update());
    }

    componentDidMount() {
        this.update();
    }

    async update() {
        await this.populateData(this.state.skip, this.state.take);
    }

    renderTable(data) {

        var columns = [
            { name: "Domain", sort: "name" },
            { name: "Account", sort: "account.companyName" },
            { name: "Created", sort: "created" },
            { name: "", sort: "", static: true }];

        return (<div className="table-responsive">
            <table className='table' aria-labelledby="tabelLabel">

                <TableHeader
                    sort={this.state.sort}
                    direction={this.state.direction}
                    columns={columns}
                    onUpdate={(sort, direction) => this.setState({
                        sort: sort,
                        direction: direction
                    }, () => this.update())}
                />
                <tbody>
                    {data.map(item =>
                        <tr key={item.id}>
                            <td>
                                {item.domain && <span>{item.domain}</span>}
                                {!item.domain && <i>Not specified</i>}
                            </td>
                            <td>
                                {item.account && <span>{item.account.companyName}</span>}
                                {!item.account && <i>Account not specified</i>}
                            </td>
                            <td>
                                {item.created && <Moment utc local format="ddd DD MMM, h:mm a">{item.created}</Moment>}
                            </td>
                            <td>
                                <Link key={item.id + "-view"} style={{ float: "right" }} className="btn mr-2 btn-outline-dark mt-2" to={"/account-domains/details/" + item.id}>Details</Link>
                                <Link key={item.id + "-edit"} style={{ float: "right" }} className="btn mr-2 btn-outline-dark mt-2" to={"/account-domains/edit/" + item.id}>Edit</Link>
                            </td>
                        </tr>
                    )}
                    {this.state.data.length === 0 && <tr><td colSpan="8"><h4 className="text-muted text-center mt-3"><i>No account domains to display</i></h4></td></tr>}
                </tbody>
            </table>
        </div>
        );
    }

    render() {


        const {
            hideFilter,
            description
        } = this.props;

        return (
            <div>

                <Card>
                    <CardHeader>
                        <Row>
                            <Col>
                                <h5> Account Domains ({this.state.total})
                                    {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                </h5>
                                <span className="d-block m-t-5 text-muted">{description} {!description && "List of account domains in the system"}</span>
                            </Col>
                            <Col>
                                <div style={{ paddingRight: "10px" }}>
                                    <Link style={{ float: "right" }} to="/account-domains/new"  className="btn mr-2 btn-outline-dark mt-2 mb-2" >New</Link>
                                    <button style={{ float: "right" }} onClick={() => { this.next(); }} disabled={this.state.loading || (this.state.skip + this.state.take >= this.state.total)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Next</button>
                                    <button style={{ float: "right" }} onClick={() => { this.previous(); }} disabled={this.state.loading || (this.state.skip <= 0)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Prev</button>
                                </div>
                            </Col>
                        </Row>

                    </CardHeader>

                </Card>
                <Card>
                    {!hideFilter && <CardHeader>
                        <Row>
                            <Col>
                                <h5>Filter</h5>
                            </Col>
                            
                            <Col>
                                <div style={{ paddingRight: "10px" }}>

                                    <InputGroup>
                                        <Input type="text" placeholder="Search" value={this.state.search} onChange={this.handleSearchChange} onKeyPress={e => e.key === 'Enter' && this.update()} />
                                        <ButtonGroup className="input-group-append">
                                            <button onClick={() => { this.update(); }} disabled={this.state.loading} className="btn btn-outline-dark" >
                                                <span className="pcoded-micon"><i className="feather icon-search"></i></span>
                                            </button>
                                        </ButtonGroup>
                                        <ButtonGroup>
                                            {(this.state.search) &&
                                                <button onClick={() => { this.clear(); }} className="btn btn-outline-dark ml-2" >
                                                    <span className="pcoded-micon">Clear</span>
                                                </button>}
                                        </ButtonGroup>
                                    </InputGroup>
                                </div>
                            </Col>
                        </Row>
                    </CardHeader>}

                    <CardBody className="p-0">
                        {this.state.data && this.renderTable(this.state.data, this.state.nextPage, this.state.previousPage)}
                    </CardBody>
                </Card>
            </div>
        );
    }

    async previous() {
        await this.populateData(this.state.skip - this.state.take, this.state.take);
    }

    async next() {
        await this.populateData(this.state.skip + this.state.take, this.state.take);
    }

    async populateData(skip, take) {

        if (!this.state.loading) {
            this.setState({ loading: true, skip: skip, take: take });

            var query = new AccountDomainQueryParams().Paginate(skip, take)
                .Search(this.state.search);

            var response = await AccountDomainApiClient.List(query
                .Sort(this.state.sort, this.state.direction));

            this.setState({ data: response.data.data, loading: false, total: response.data.total });
        }
    }
}
