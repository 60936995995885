import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Spinner, Card, CardHeader, Row, Col, CardBody } from "reactstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import {
  AccountApiClient,
  AccountQueryParams,
} from "../../infrastructure/MyDayClient/Accounts";
import { AccountSelector } from "../../infrastructure/AccountSelector";

export class AccountsNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: false,
      next: false,
    };
  }

  renderForm() {
    return (
      <Formik
        initialValues={{
          companyName: "",
          legalName: "",
          address1: "",
          address2: "",
          address3: "",
          address4: "",
          identityProvider: "None",
          clientId: "",
          tenantId: "",
          groupSyncEnabled: false,
          templateAccountId: "",
          externalBilling: "",
        }}
        onSubmit={async (fields, { setErrors, setFieldError }) => {
          if (!fields.companyName) {
            setFieldError("companyName", "Please specify the company name");
            return;
          }

          var response = await AccountApiClient.Create(
            fields.companyName,
            fields.legalName,
            fields.address1,
            fields.address2,
            fields.address3,
            fields.address4,
            fields.country,
            fields.contactName,
            fields.email,
            fields.mobile,
            fields.identityProvider,
            fields.clientId,
            fields.tenantId,
            fields.groupSyncEnabled,
            fields.templateAccountId ? fields.templateAccountId.value : null,
            fields.externalBilling
          );

          if (!response.successful) {
            response.validationErrors.map((error) => {
              setFieldError(error.key, error.message);
              return {
                [error.key]: error.message,
              };
            });
          } else {
            this.setState({ next: true, id: response.data.id });
          }
        }}
      >
        {({
          errors,
          status,
          touched,
          isSubmitting,
          values,
          setFieldValue,
          setFieldTouched,
          handleChange,
          handleBlur,
          fields,
        }) => (
          <Form>
            <Row>
              <Col>
                <div className="form-group">
                  <label htmlFor="companyName">Company name*</label>
                  <Field
                    name="companyName"
                    placeholder="E.g. Seveno"
                    type="text"
                    className={
                      "bgWhite form-control" +
                      (errors.companyName && touched.companyName
                        ? " is-invalid"
                        : "")
                    }
                  />
                  <ErrorMessage
                    name="companyName"
                    component="div"
                    className="invalid-feedback text-left"
                  />
                </div>
              </Col>
              <Col>
                <div className="form-group">
                  <label htmlFor="legalName">Legal name</label>
                  <Field
                    name="legalName"
                    placeholder="E.g. Seveno Ltd"
                    type="text"
                    className={
                      "bgWhite form-control" +
                      (errors.legalName && touched.legalName
                        ? " is-invalid"
                        : "")
                    }
                  />
                  <ErrorMessage
                    name="legalName"
                    component="div"
                    className="invalid-feedback text-left"
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="form-group">
                  <label htmlFor="contactName">External Billing</label>
                  <Field
                    name="externalBilling"
                    placeholder="External Billing"
                    type="text"
                    className={
                      "bgWhite form-control" +
                      (errors.externalBilling && touched.externalBilling
                        ? " is-invalid"
                        : "")
                    }
                  />
                  <ErrorMessage
                    name="externalBilling"
                    component="div"
                    className="invalid-feedback text-left"
                  />
                </div>
              </Col>
            </Row>

            <h5>Address</h5>
            <hr />
            <Row>
              <Col>
                <div className="form-group">
                  <label htmlFor="address1">Address</label>
                  <Field
                    name="address1"
                    placeholder="20 Example Place"
                    type="text"
                    className={
                      "bgWhite form-control" +
                      (errors.address1 && touched.address1 ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="address1"
                    component="div"
                    className="invalid-feedback text-left"
                  />
                </div>
              </Col>
              <Col>
                <div className="form-group">
                  <label htmlFor="address2">Suburb</label>
                  <Field
                    name="address2"
                    placeholder="Example Suburb"
                    type="text"
                    className={
                      "bgWhite form-control" +
                      (errors.address2 && touched.address2 ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="address2"
                    component="div"
                    className="invalid-feedback text-left"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="form-group">
                  <label htmlFor="address3">Town</label>
                  <Field
                    name="address3"
                    placeholder="20 Example Place"
                    type="text"
                    className={
                      "bgWhite form-control" +
                      (errors.address3 && touched.address3 ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="address3"
                    component="div"
                    className="invalid-feedback text-left"
                  />
                </div>
              </Col>
              <Col>
                <div className="form-group">
                  <label htmlFor="address4">Region</label>
                  <Field
                    name="address4"
                    placeholder="Example Region"
                    type="text"
                    className={
                      "bgWhite form-control" +
                      (errors.address4 && touched.address4 ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="address4"
                    component="div"
                    className="invalid-feedback text-left"
                  />
                </div>
              </Col>
              <Col>
                <div className="form-group">
                  <label htmlFor="country">Country</label>
                  <Field
                    name="country"
                    placeholder="New Zealand"
                    type="text"
                    className={
                      "bgWhite form-control" +
                      (errors.country && touched.country ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="country"
                    component="div"
                    className="invalid-feedback text-left"
                  />
                </div>
              </Col>
            </Row>

            <h5>Primary Contact</h5>
            <hr />
            <Row>
              <Col>
                <div className="form-group">
                  <label htmlFor="contactName">Name</label>
                  <Field
                    name="contactName"
                    placeholder="Fullname"
                    type="text"
                    className={
                      "bgWhite form-control" +
                      (errors.contactName && touched.contactName
                        ? " is-invalid"
                        : "")
                    }
                  />
                  <ErrorMessage
                    name="contactName"
                    component="div"
                    className="invalid-feedback text-left"
                  />
                </div>
              </Col>
              <Col>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <Field
                    name="email"
                    placeholder="sample@example.com"
                    type="text"
                    className={
                      "bgWhite form-control" +
                      (errors.email && touched.email ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="invalid-feedback text-left"
                  />
                </div>
              </Col>
              <Col>
                <div className="form-group">
                  <label htmlFor="mobile">Mobile</label>
                  <Field
                    name="mobile"
                    placeholder="+64211234567"
                    type="text"
                    className={
                      "bgWhite form-control" +
                      (errors.mobile && touched.mobile ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="mobile"
                    component="div"
                    className="invalid-feedback text-left"
                  />
                </div>
              </Col>
            </Row>

            <h5>Identity Provider</h5>
            <hr />
            <Row>
              <Col>
                <div className="form-group">
                  <Field
                    name="identityProvider"
                    as="select"
                    className={
                      "form-control" +
                      (errors.identityProvider && touched.identityProvider
                        ? " is-invalid"
                        : "")
                    }
                  >
                    <option value="None">None</option>
                    <option value="AzureAD">AzureAD</option>
                  </Field>
                  <ErrorMessage
                    name="identityProvider"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </Col>
            </Row>
            {values.identityProvider == "AzureAD" && (
              <>
                <Row>
                  <Col>
                    <div className="form-group">
                      <label htmlFor="tenantId">Tenant Id</label>
                      <Field
                        name="tenantId"
                        placeholder=""
                        type="text"
                        className={
                          "bgWhite form-control" +
                          (errors.tenantId && touched.tenantId
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="tenantId"
                        component="div"
                        className="invalid-feedback text-left"
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="form-group">
                      <label htmlFor="clientId">Client Id</label>
                      <Field
                        name="clientId"
                        placeholder=""
                        type="text"
                        className={
                          "bgWhite form-control" +
                          (errors.clientId && touched.clientId
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="clientId"
                        component="div"
                        className="invalid-feedback text-left"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="align-self-center">
                    <div className="form-group">
                      <div className="form-check">
                        <Field
                          className="form-check-input"
                          type="checkbox"
                          name="groupSyncEnabled"
                          id="groupSync"
                        />
                        <label className="form-check-label" htmlFor="groupSync">
                          Group Sync Enabled
                        </label>
                      </div>
                    </div>
                  </Col>
                </Row>
              </>
            )}

            <h5>Clone settings from</h5>
            <hr />
            <Row>
              <Col>
                <AccountSelector
                  placeholder="Do not clone any settings"
                  name="templateAccountId"
                  value={values.templateAccountId}
                  onChange={setFieldValue}
                />
              </Col>
            </Row>

            <hr />
            <div className="form-group">
              <button
                type="submit"
                className="btn mr-2 btn-outline-dark mt-2"
                disabled={isSubmitting}
              >
                {!isSubmitting && "Create"}
                {isSubmitting && <Spinner animation="border" />}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }

  render() {
    if (this.state.next === true) {
      return <Redirect to={"/accounts/details/" + this.state.id} />;
    } else {
      return (
        <div>
          <Card>
            <CardHeader>
              <Row>
                <Col>
                  <h5>
                    {" "}
                    New account
                    {this.state.loading && (
                      <Spinner
                        style={{
                          height: "18px",
                          width: "18px",
                          marginLeft: "10px",
                        }}
                        animation="border"
                      />
                    )}
                  </h5>
                  <span className="d-block m-t-5 text-muted">
                    Add a new account to the MyDay system
                  </span>
                </Col>
                <Col>
                  <div style={{ paddingRight: "10px" }}>
                    <Link
                      style={{ float: "right" }}
                      to="/"
                      className="btn mr-2 btn-outline-dark mt-2 mb-2"
                    >
                      Cancel
                    </Link>
                  </div>
                </Col>
              </Row>
            </CardHeader>

            <CardBody>
              {this.state.data && this.renderForm(this.state.data)}
            </CardBody>
          </Card>
        </div>
      );
    }
  }
}
