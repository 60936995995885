import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { AccountDomainApiClient, AccountDomainQueryParams } from '../../infrastructure/MyDayClient/AccountDomains';
import { AccountSelector } from '../../infrastructure/AccountSelector';

export class AccountDomainsNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [], loading: false, next: false
        };

    }

    renderForm() {
        return (<Formik
            initialValues={{
                domain: '',
                accountId: ''
            }}
            onSubmit={async (fields, { setErrors, setFieldError }) => {

                if (!fields.domain) {
                    setFieldError("domain", "Please specify the email");
                    return;
                }
                if (!fields.accountId) {
                    setFieldError("accountId", "Please specify the account to add this domain to");
                    return;
                }

                var response = await AccountDomainApiClient.Create(
                    fields.domain,
                    fields.accountId ? fields.accountId.value : null
                );

                if (!response.successful) {

                    response.validationErrors.map(error => {
                        setFieldError(error.key, error.message);
                        return {
                            [error.key]: error.message
                        };
                    });

                } else {
                    this.setState({ next: true, id: response.data.id });
                }

            }}
        >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur, fields }) => (
            <Form>
                <Row>
                    <Col>
                        <div className="form-group">
                                <label htmlFor="domain">Domain*</label>
                                <Field name="domain" placeholder="seveno.nz" type="text" className={'bgWhite form-control' + (errors.domain && touched.domain ? ' is-invalid' : '')} />
                                <ErrorMessage name="domain" component="div" className="invalid-feedback text-left" />
                        </div>
                    </Col>
                    </Row>
                    <Row>
                        <Col className={(errors.accountId && touched.accountId ? ' is-invalid' : '')} >
                            <AccountSelector placeholder="Account to add to" name="accountId" value={values.accountId} onChange={setFieldValue} />
                            <ErrorMessage name="accountId" component="div" className="invalid-feedback text-left" />
                        </Col>
                    </Row>
                <hr />
                <div className="form-group">
                    <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                        {!isSubmitting && "Add"}
                        {isSubmitting && <Spinner animation="border" />}
                    </button>
                </div>
            </Form>
        )}</Formik>);
    }

    render() {

        if (this.state.next === true) {
            return (<Redirect to={"/account-domains/details/" + this.state.id} />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> New account domain
                                        {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Add a new onboarding domain to an account</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to="/invitations" className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.state.data && this.renderForm(this.state.data)}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

}