import React, { Component } from "react";
import { SelectField } from "./SelectField";
import { RoleQueryParams, RoleApiClient } from "./MyDayClient/Roles";

class RoleSelector extends Component {

    constructor(props) {
        super(props);
        this.state = { init: false };
    }

    async componentDidMount() {
        await this.loadData();
    }


    async componentDidUpdate(prevProps) {

        const {
            accountId
        } = this.props;

        if (prevProps.accountId !== accountId)
            await this.loadData();

    }

    async loadData() {

        const {
            initialId,
            displayFormat,
            name,
            initComplete,
            accountId
        } = this.props;

        console.log(accountId);

        var displayFormatter = (item) => {

            var display = displayFormat;

            if (!displayFormat)
                display = (item) => item.name;

            return display(item);

        }

        var query = new RoleQueryParams();

        if (accountId)
            query = query.WithAccount(accountId);

        var entities = (await RoleApiClient.List(query.Paginate(0, 10000))).data;

        if (entities) {
            this.setState({
                options: entities.data.map(function (item) {
                    return {
                        value: item.id,
                        label: displayFormatter(item)
                    };
                })
            }, () => {
                if (initialId && !this.state.init) {
                    this.handleChange(name ? name : "roleId", this.state.options.filter(i => i.value === initialId)[0]);
                }
                this.setState({
                    init: true
                });
            });
        }


        if (initComplete)
            initComplete();
    }

    handleChange = (name, value) => {
        const { onChange } = this.props;

        this.setState({ inputValue: value });

        onChange(name, value);
    };

    render() {
        const {
            id,
            name,
            label,
            placeholder,
            value,
            isMulti,
            isDisabled,
            touched,
            error,
            onBlur,
            hideLabel,
        } = this.props;

        return (this.state.init && <SelectField
            id={id ? id : "roleId"}
            name={name ? name : "roleId"}
            label={label ? label : hideLabel ? null : "Role"}
            placeholder={placeholder ? placeholder : "Select role"}
            options={this.state.options}
            value={value}
            onChange={this.handleChange}
            onBlur={onBlur}
            touched={touched}
            isMulti={isMulti}
            error={error}
            isDisabled={isDisabled}
            isClearable={true}
            backspaceRemovesValue={true}
        />);
    }
}

export { RoleSelector };
