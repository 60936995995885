import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Spinner, Card, CardHeader, Row, Col, CardBody } from "reactstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import {
  InvitationApiClient,
  InvitationQueryParams,
} from "../../infrastructure/MyDayClient/Invitations";
import { AccountSelector } from "../../infrastructure/AccountSelector";
import { RoleSelector } from "../../infrastructure/RoleSelector";
import { TeamSelector } from "../../infrastructure/TeamSelector";
import { UserTypeSelector } from "../../infrastructure/UserTypeSelector";

export class InvitationsNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: false,
      next: false,
    };
  }

  renderForm() {
    return (
      <Formik
        initialValues={{
          name: "",
          surname: "",
          email: "",
          roleId: "",
          teamId: "",
          hasCrmAccess: false,
          accountId: "",
          userTypeId: "",
        }}
        onSubmit={async (fields, { setErrors, setFieldError }) => {
          if (!fields.email) {
            setFieldError("email", "Please specify the email");
            return;
          }
          if (!fields.accountId) {
            setFieldError(
              "accountId",
              "Please specify the account to invite this user to"
            );
            return;
          }

          if (!fields.userTypeId) {
            setFieldError("userTypeId", "Please specify the user type");
            return;
          }

          var response = await InvitationApiClient.Create(
            fields.name,
            fields.email,
            fields.accountId ? fields.accountId.value : null,
            fields.surname,
            fields.roleId ? fields.roleId.value : null,
            fields.hasCrmAccess,
            fields.teamId ? fields.teamId.value : null,
            fields.userTypeId ? fields.userTypeId.value : null
          );

          if (!response.successful) {
            response.validationErrors.map((error) => {
              setFieldError(error.key, error.message);
              return {
                [error.key]: error.message,
              };
            });
          } else {
            this.setState({ next: true, id: response.data.id });
          }
        }}
      >
        {({
          errors,
          status,
          touched,
          isSubmitting,
          values,
          setFieldValue,
          setFieldTouched,
          handleChange,
          handleBlur,
          fields,
        }) => (
          <Form>
            <Row>
              <Col>
                <div className="form-group">
                  <label htmlFor="name">Name*</label>
                  <Field
                    name="name"
                    placeholder="E.g. John"
                    type="text"
                    className={
                      "bgWhite form-control" +
                      (errors.name && touched.name ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="invalid-feedback text-left"
                  />
                </div>
              </Col>
              <Col>
                <div className="form-group">
                  <label htmlFor="surname">Surname</label>
                  <Field
                    name="surname"
                    placeholder="E.g. Doe"
                    type="text"
                    className={
                      "bgWhite form-control" +
                      (errors.surname && touched.surname ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="surname"
                    component="div"
                    className="invalid-feedback text-left"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <Field
                    name="email"
                    placeholder="email@example.com"
                    type="text"
                    className={
                      "bgWhite form-control" +
                      (errors.email && touched.email ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="invalid-feedback text-left"
                  />
                </div>
              </Col>
            </Row>

            <h5>Access</h5>
            <hr />
            <Row>
              <Col
                className={
                  errors.accountId && touched.accountId ? " is-invalid" : ""
                }
              >
                <AccountSelector
                  placeholder="Account to add to"
                  name="accountId"
                  value={values.accountId}
                  onChange={(a, b) => {
                    setFieldValue(a, b);
                    setFieldValue("roleId", null);
                    setFieldValue("teamId", null);
                    setFieldValue("userTypeId", null);
                  }}
                />
                <ErrorMessage
                  name="accountId"
                  component="div"
                  className="invalid-feedback text-left"
                />
              </Col>
              <Col className="align-self-center">
                {!values.accountId && (
                  <>
                    <label htmlFor="roleId">Role</label>
                    <br />
                    <i>Select an account first</i>
                  </>
                )}
                {values.accountId && (
                  <>
                    <RoleSelector
                      placeholder="Role"
                      name="roleId"
                      value={values.roleId}
                      onChange={setFieldValue}
                      accountId={values.accountId.value}
                    />
                    <ErrorMessage
                      name="roleId"
                      component="div"
                      className="invalid-feedback text-left"
                    />
                  </>
                )}
              </Col>

              <Col className="align-self-center">
                {!values.accountId && (
                  <>
                    <label htmlFor="roleId">User Type</label>
                    <br />
                    <i>Select an account first</i>
                  </>
                )}
                {values.accountId && (
                  <>
                    <UserTypeSelector
                      placeholder="User Type"
                      name="userTypeId"
                      value={values.userTypeId}
                      onChange={setFieldValue}
                      accountId={values.accountId.value}
                    />
                    <ErrorMessage
                      name="userTypeId"
                      component="div"
                      className="invalid-feedback text-left"
                    />
                  </>
                )}
              </Col>
              <Col className="align-self-center">
                {!values.accountId && (
                  <>
                    <label htmlFor="roleId">Team</label>
                    <br />
                    <i>Select an account first</i>
                  </>
                )}
                {values.accountId && (
                  <>
                    {" "}
                    <TeamSelector
                      placeholder="Team"
                      name="teamId"
                      value={values.teamId}
                      onChange={setFieldValue}
                      accountId={values.accountId.value}
                    />
                    <ErrorMessage
                      name="teamId"
                      component="div"
                      className="invalid-feedback text-left"
                    />
                  </>
                )}
              </Col>
            </Row>
            <Row className="pt-2">
              <Col className="align-self-center">
                <div className="form-group">
                  <div className="form-check">
                    <Field
                      className="form-check-input"
                      type="checkbox"
                      name="hasCrmAccess"
                      id="crmAccess"
                    />
                    <label className="form-check-label" htmlFor="crmAccess">
                      Portal Access
                    </label>
                  </div>
                </div>
              </Col>
            </Row>

            <hr />
            <div className="form-group">
              <button
                type="submit"
                className="btn mr-2 btn-outline-dark mt-2"
                disabled={isSubmitting}
              >
                {!isSubmitting && "Invite"}
                {isSubmitting && <Spinner animation="border" />}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }

  render() {
    if (this.state.next === true) {
      return <Redirect to={"/invitations/details/" + this.state.id} />;
    } else {
      return (
        <div>
          <Card>
            <CardHeader>
              <Row>
                <Col>
                  <h5>
                    {" "}
                    New invitation
                    {this.state.loading && (
                      <Spinner
                        style={{
                          height: "18px",
                          width: "18px",
                          marginLeft: "10px",
                        }}
                        animation="border"
                      />
                    )}
                  </h5>
                  <span className="d-block m-t-5 text-muted">
                    Invite a new user to an account
                  </span>
                </Col>
                <Col>
                  <div style={{ paddingRight: "10px" }}>
                    <Link
                      style={{ float: "right" }}
                      to="/invitations"
                      className="btn mr-2 btn-outline-dark mt-2 mb-2"
                    >
                      Cancel
                    </Link>
                  </div>
                </Col>
              </Row>
            </CardHeader>

            <CardBody>
              {this.state.data && this.renderForm(this.state.data)}
            </CardBody>
          </Card>
        </div>
      );
    }
  }
}
