import React, { Component } from "react";
import {
  Collapse,
  NavbarToggler,
  Modal,
  ModalHeader,
  ModalBody,
  Spinner,
} from "reactstrap";
import { Link, Route } from "react-router-dom";
import { MyDayAdminAPI } from "../../infrastructure/MyDayAdminAPI";

export class SideBar extends Component {
  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true,
    };
  }

  async componentDidMount() {}

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }

  render() {
    return (
      <div>
        <NavbarToggler
          className="d-block d-md-none bg-dark sidebar"
          onClick={this.toggleNavbar}
        >
          <span style={{ color: "white" }}>View menu</span>
        </NavbarToggler>

        <Collapse
          className="d-sm-inline-flex flex-sm-row-reverse"
          isOpen={!this.state.collapsed}
          navbar
          style={{ width: "100%", minWidth: "200px" }}
        >
          <nav
            className="sidebar pcoded-navbar menu-light"
            style={{ paddingTop: "10px", zIndex: "0" }}
          >
            <div
              className="sidebar-sticky"
              style={{ width: "100%", minWidth: "200px" }}
            >
              <ul className="nav flex-column pcoded-inner-navbar">
                <li className="nav-item pcoded-menu-caption">
                  <label>Admin</label>
                </li>

                <Route path="/" exact>
                  {({ match }) => (
                    <li
                      className={
                        (match ? "active " : undefined) +
                        "nav-item pcoded-hasmenu"
                      }
                    >
                      <Link className="nav-link" to="/">
                        <span className="pcoded-micon">
                          <i className="feather icon-pie-chart"></i>
                        </span>
                        <span className="pcoded-mtext">Accounts</span>
                      </Link>
                    </li>
                  )}
                </Route>

                <Route path="/invitations">
                  {({ match }) => (
                    <li
                      className={
                        (match ? "active " : undefined) +
                        "nav-item pcoded-hasmenu"
                      }
                    >
                      <Link className="nav-link" to="/invitations">
                        <span className="pcoded-micon">
                          <i className="feather icon-user"></i>
                        </span>
                        <span className="pcoded-mtext">Invitations</span>
                      </Link>
                    </li>
                  )}
                </Route>

                <Route path="/account-domains">
                  {({ match }) => (
                    <li
                      className={
                        (match ? "active " : undefined) +
                        "nav-item pcoded-hasmenu"
                      }
                    >
                      <Link className="nav-link" to="/account-domains">
                        <span className="pcoded-micon">
                          <i className="feather icon-home"></i>
                        </span>
                        <span className="pcoded-mtext">Account Domains</span>
                      </Link>
                    </li>
                  )}
                </Route>

                <Route path="/account-users">
                  {({ match }) => (
                    <li
                      className={
                        (match ? "active " : undefined) +
                        "nav-item pcoded-hasmenu"
                      }
                    >
                      <Link className="nav-link" to="/account-users">
                        <span className="pcoded-micon">
                          <i className="feather icon-user"></i>
                        </span>
                        <span className="pcoded-mtext">Account Users</span>
                      </Link>
                    </li>
                  )}
                </Route>

                <Route path="/export-billing-file">
                  {({ match }) => (
                    <li
                      className={
                        (match ? "active " : undefined) +
                        "nav-item pcoded-hasmenu"
                      }
                    >
                      <Link className="nav-link" to="/export-billing-file">
                        <span className="pcoded-micon">
                          <i className="feather icon-file-text"></i>
                        </span>
                        <span className="pcoded-mtext">
                          Export Billing File
                        </span>
                      </Link>
                    </li>
                  )}
                </Route>
              </ul>
            </div>
          </nav>
        </Collapse>
      </div>
    );
  }
}
